import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";

import { useHistory } from "react-router-dom";
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'


//img
import headerBg from '../../assets/img/landingPage/ilustracion-home_v4.png'
import virtualApi from '../../assets/img/landingPage/02_video@2x.png'
import apilife from '../../assets/img/landingPage/apiLife.png'
import openAi from '../../assets/img/landingPage/openapi-blanco.png'
import linux from '../../assets/img/landingPage/Linux.png'
import tablet from '../../assets/img/landingPage/tablet.png'
import frameTablet from '../../assets/img/landingPage/iPad_16.9.png'

import gradientCollab from '../../assets/img/landingPage/gradient_collab.png'
import gradientApi from '../../assets/img/landingPage/gradientApi.png'
import gradientServices from '../../assets/img/landingPage/gradientServices.png'
import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import icn_design from '../../assets/img/landingPage/icono-design@2x.png'
import icn_implementation from '../../assets/img/landingPage/icono-implementation@2x.png'
import icn_deploy from '../../assets/img/landingPage/icono-deployment@2x.png'
import icn_testing from '../../assets/img/landingPage/iconos-testing@2x.png'
import icn_versioning from '../../assets/img/landingPage/iconos-versiones@2x.png'

import product_avap from '../../assets/img/landingPage/AVAP.png'
import product_vs from '../../assets/img/landingPage/VirtualServer.png'
import product_gateway from '../../assets/img/landingPage/Gateway.png'
import product_cloud from '../../assets/img/landingPage/Cloud.png'
import product_collab from '../../assets/img/landingPage/CollaborativeNetwork.png'
import product_avds from '../../assets/img/landingPage/DevStudio.png'
import img_lifecycle from '../../assets/img/landingPage/CicloVida.png'
import product_mesh from '../../assets/img/landingPage/MeshManager.png'
import product_commander from '../../assets/img/landingPage/Commander.png'
import product_vision from '../../assets/img/landingPage/Vision.png'





//logos
import logo_vs from '../../assets/img/logos/AVAP_VS_white.svg'
import logo_avap from '../../assets/img/logos/AVAP_Lenguaje_white.svg'
import logo_gate from '../../assets/img/logos/AVAP_Gateway_white.svg'
import logo_cloud from '../../assets/img/logos/AVAP_Cloud_white.svg'
import logo_collab from '../../assets/img/logos/AVAP_Collaborative_white.svg'
import logo_avds from '../../assets/img/logos/AVAP_DS_white.svg'
import logo_avapConnect from '../../assets/img/landingPage/AVAP_Connect.svg'
import logo_mesh from '../../assets/img/logos/AVAP_ManagementMesh_white.svg'
import logo_commander from '../../assets/img/logos/AVAP_Commander_white.svg'
import logo_vision from '../../assets/img/logos/AVAP_Vision-negativo.svg'



const App = () => {
    const history = useHistory();
    const [selectedProduct, setSelectedProduct] = useState('AVAP');
    const productDetails = {
    /*     AVAP: {
            title: 'Advanced Virtual API Programming',
            text: 'Virtual, AI-enhanced, high-level specific language for API development. With features like language specifications, an interpreter, and a server, it lets developers focus on problem-solving in a new era of virtual programming.',
            image: product_avap,
            route: '/avap',
        }, */
        AVS: {
            title: 'Manage and publish APIs across environments',
            text: 'AVS supports multiple API versions, automates testing, and streamlines deployment. Its API Wrapped feature enables seamless adaptation between REST, SOAP, and other protocols for external layers.',
            image: product_vs,
            route: '/avs',
        },
        AVAPGA: {
            title: 'Scalable virtual proxy management',
            text: 'AVAP Gateway Advanced manages virtual proxies, generating multiple instances and intelligently redirecting requests based on history, scenarios, and conditions, ensuring seamless compatibility with any language server.',
            image: product_gateway,
            route: '/gateway',
        },
        Servicios: {
            title: 'Power platform for the API Lifecycle Management',
            text: 'Manage your APIs in a SaaS model. Publish APIs and choose database location. Powered by AVAP technology, virtuality and artificial intelligence will be in the core of every API and API product.',
            image: product_cloud,
            route: '/cloud',
        },
      /*   AVAPCN: {
            title: 'First democratic P2P network for APIs',
            text: 'P2P platform that enables scalable deployment of AVS and AVAP Interpreter & Server Language for API development. It optimizes API requests by redirecting to faster servers and enhances virtual machine capacity through dedicated channels.',
            image: product_collab,
            route: '/collaborative',
        },
        AVAPDevStudio: {
            title: 'AVAP own IDE',
            text: 'Robust IDE that streamlines project management, API development, and publication. It enhances collaboration with AI-driven coding extensions, co-coding features, and integrated chat management.',
            image: product_avds,
            route: '/devstudio',
        }, */
        AVAPMesh: {
            title: 'Seamless hybrid mesh management',
            text: 'Manage hybrid architectures via a unified interface, optimizing resource distribution, scaling, and load balancing without physical resource movement, combining on-premise and logical configurations to adapt to your needs.',
            image: product_mesh,
            route: '/meshmanager',
        },
        AVAPCommander: {
            title: 'The frontline of API lifecycle management',
            text: 'Manage your API lifecycle from creation to deployment. A unified interface for AVAP Cloud and on-premise platforms, powered by AI-driven technology and virtuality for seamless API management.',
            image: product_commander,
            route: '/commander',
        },
        AVAPVision: {
            title: 'Next-gen API observability and performance insights with AI.',
            text: 'Optimize, analyze, and scale API performance with real-time insights, AI-driven analytics, and seamless monitoring for enhanced scalability, security, and operational efficiency.',
            image: product_vision,
            route: '/avap-vision',
        },
    };

    const getLogo = (productKey) => {
        switch (productKey) {
            case 'AVAP':
                return logo_avap;
            case 'AVS':
                return logo_vs;
            case 'AVAPGA':
                return logo_gate;
            case 'Servicios':
                return logo_cloud;
            case 'AVAPCN':
                return logo_collab;
            case 'AVAPDevStudio':
                return logo_avds;
            case 'AVAPMesh':
                return logo_mesh;
            case 'AVAPCommander':
                return logo_commander;
            case 'AVAPVision':
                return logo_vision;
            default:
                return logo_avap;
        }
    };



    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    useEffect(() => {
        const cursor = document.querySelector('.blob');

        const handleMouseMove = (e: MouseEvent) => {
            if (cursor && cursor instanceof HTMLElement) {
                cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);


    return (
        <>
            <FlNav></FlNav>
            <div className="fl-mainWrapper">
                <div className="fl-wrapper">
                    <div className="fl-container">
                        <header id="fl-header" style={{ backgroundImage: `url(${headerBg})` }}>
                            <div className="fl-content-wrapper">
                                <h1 className="fl-title-big text-center">API Lifecycle Management Solution 
                                </h1>
                                <p className="fl-title text-center m-0 gradient-text">Virtuality and AI at its core</p>
                                <p className="fl-text text-center fl-txt-container">
                                    Efficient, intelligent API management powered by next-gen technology,
                                    delivering seamless scalability and enhanced profitability
                                    for your team and operations
                                </p>

                                <a className="glowBtn" href="https://staging.avapcloud.com/business_en" target='_blank'>
                                    <span className="btnInner">   AVAP Cloud Start for Free</span>
                                </a>
                            </div>
                        </header>
                    </div>
                </div>

                <div className="fl-wrapper">
                    <img src={gradientApi} alt="" className="gradient gradientapi" />
                    <div className="fl-container">
                        <section id="apiSection" className=" mt-3">

                            <h3 className="fl-title text-center">An API Virtualization Management Platform</h3>
                            <p className="fl-text text-center m-0">AVAP Framework is a comprehensive suite of products and services to
                                manage <br />the entire API lifecycle, from development to maintenance and deployment.</p>

                            {/*  <a href="https://www.youtube.com/watch?v=LlVcKzcM3uc" target='_blank' className="text-center fl-link my-5"> Watch video <FontAwesomeIcon size="xs" icon={faArrowRight} /></a> */}


                            <div className="ytWrapper">
                                <img src={frameTablet} alt="" className="frameTablet" />
                                <iframe className='fl-video-container' src="https://www.youtube.com/embed/LlVcKzcM3uc?si=RbgB1V_dJPcusm5y&amp;controls=0&rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                            </div>


                            {/*        <img src={virtualApi} alt="" /> */}

                        </section>

                    </div>
                </div>

                <div className="fl-wrapper">
                    <div className="fl-container">
                        <h3 className="fl-title text-center">API Lifecycle</h3>
                        <img src={img_lifecycle} className='my-4 d-block' style={{ maxWidth: '1180px', width: '100%', margin: '0px auto', padding: '50px 0px' }} alt="" />
                    </div>
                </div>





                {/* <div className="fl-wrapper">
                    <div className="fl-container">
                        <section id="characteristics">
                            <div className="charWrapper">
                                <div className="fl-item">
                                    Designing
                                    <div className="fl-border"></div>
                                    <img src={icn_design} alt="" />
                                </div>
                                <div className="fl-item">
                                    Implementation
                                    <div className="fl-border"></div>
                                    <img src={icn_implementation} alt="" />
                                </div>

                                <div className="fl-item">
                                    Testing
                                    <div className="fl-border"></div>
                                    <img src={icn_testing} alt="" />
                                </div>
                                <div className="fl-item">
                                    Deployment
                                    <div className="fl-border"></div>
                                    <img src={icn_deploy} alt="" />
                                </div>
                                <div className="fl-item">
                                    Versioning of retirement
                                    <div className="fl-border"></div>
                                    <img src={icn_versioning} alt="" />
                                </div>

                            </div>
                        </section>

                    </div>
                </div> */}



                <div className="fl-wrapper">
                    <img src={gradientServices} className="gradient gradientServices" alt="" />
                    <img src={gradientApi} alt="" className="gradient gradientlife" />
                    <div className="fl-container">
                        <section id="products">
                            {/*   <h3 className="fl-title text-center">Our Products & Services</h3> */}

                            <div className="fl-productsWrapper">
                                {Object.keys(productDetails).map((productKey, index) => (
                                    <div key={productKey} className="fl-product-item">
                                        <div className={`row align-items-center ${index % 2 === 0 ? '' : 'flex-row-reverse'}`}>
                                            <div className="col-md-6">
                                                <div className="product-content">
                                                    <img className="logo" src={getLogo(productKey)} alt={productKey} />
                                                    <h3 className="fl-subtitle">
                                                        {productDetails[productKey].title}
                                                    </h3>
                                                    <p className="fl-text">
                                                        {productDetails[productKey].text}
                                                    </p>
                                                    <a
                                                        onClick={() => {
                                                            history.push(productDetails[productKey].route);
                                                            window.scrollTo(0, 0);
                                                        }}
                                                        className="fl-link"
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        Learn more <FontAwesomeIcon size="xs" icon={faArrowRight} />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <img
                                                    src={productDetails[productKey].image}
                                                    alt={`${productKey} preview`}
                                                    className="img-fluid product-preview"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                </div>



                {/* <div className="fl-wrapper">
                    <div className="fl-container">

                        <section id="box">
                            <div className="fl-box">
                                <p>Official members of global tech communities, enhancing our commitment
                                    to open API standards and innovation
                                </p>
                                <div className="imgWrapper">
                                    <img src={openAi} alt="" />
                                    <img src={linux} alt="" />
                                </div>
                            </div>
                        </section>

                    </div>
                </div> */}

                <div className="container ">
                    <div className="row">
                        <div className="col">
                            <div className="blobContainer mb-8 mt-4">

                                <div className="blob"></div>
                                <div className="leftBC">
                                    <img src={openAi} alt="" />
                                    <img src={linux} alt="" />
                                </div>

                                <div className="rightBC">
                                    <h3 className='fl-title'>AVAP Joins OpenAPI & Linux Foundation</h3>
                                    <p>AVAP is an official member of the OpenAPI Initiative and Linux Foundation, advancing our commitment to open technology, API standardization, and innovation. We’re excited to collaborate with industry leaders to drive the future of API development.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="fl-wrapper mb-5">
                        <div className="fl-container">
                            <section id="avapCollab">
                                {/*   <img className="logoTitle" src={logo_collab} alt="" /> */}
                                <h3 className='fl-title'>
                                    AVAP Education
                                </h3>


                                <h3 className="fl-subtitle mt-5">
                                    Empowering Technological Education Through Collaboration
                                </h3>
                                <div className="imgWrapper">
                                    <img src={gradientCollab} alt="" />
                                    <img src={tablet} alt="" className="mt-5" />
                                </div>
                                <p className="text-center fl-c-gray">
                                    We're excited to offer AVAP technology to the world, providing free access for everyone to learn, test, and collaborate.
                                    With AVAP Collaborative Network, a P2P platform we aim to empower users to develop and publish APIs with cutting-edge AVAP technology.
                                    <br />
                                    <br />

                                    We’re committed to equipping the next generation of engineers, developers and tech experts, with the best tools and technology. By offering AVAP technology for free, we provide learners with the opportunity to gain hands-on experience with advanced API development. Through this, we aim to foster a deeper understanding of the future of digital infrastructure, enabling people to innovate and lead in the world of API technology.

                                </p>

                                <a onClick={() => {
                                    history.push("/collaborative");
                                    window.scrollTo(0, 0);
                                }} className='fl-link'>Learn more</a>

                            </section>

                        </div>
                    </div>


                    {/* <div className="fl-wrapper fl-z-index-2">
                        <div className="fl-container">
                            <section id="mainFeatures">
                                <h3 className="fl-title">
                                    Main Features
                                </h3>

                                <div className="fl-itemsWrapper">
                                    <div className="fl-darkButton">
                                        <div className="itemButton">
                                            <p className=''>Free and International Network</p>
                                            <img src={icon_free} alt="" />
                                        </div>
                                    </div>
                                    <div className="fl-darkButton">
                                        <div className="itemButton">
                                            <p className=''>Exponential Capacity</p>
                                            <img src={icon_expo} alt="" />
                                        </div>
                                    </div>
                                    <div className="fl-darkButton">
                                        <div className="itemButton">
                                            <p className=' text-capitalize'>Dynamic thread distribution</p>
                                            <img src={icon_dyn} alt="" />
                                        </div>
                                    </div>
                                    <div className="fl-darkButton">
                                        <div className="itemButton">
                                            <p className=' text-capitalize'>Intelligent Capacity</p>
                                            <img src={icon_expo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div> */}


                    <div className="fl-wrapper">
                        <div className="fl-container fl-z-index-2">
                            <section id="join">
                                <img className="connectLogo" src={logo_avapConnect} alt="" />
                                <h3 className="text-center fl-subtitle m-0">Join AVAP Partner Network</h3>
                                <p className="text-center fl-subtitle-blue">
                                    Take advantage of the Early Access program
                                </p>
                                <p className="text-center fl-c-gray">
                                    Join our program for international business partners to access a unified infrastructure, powerful sales tools, and comprehensive support. Unlock pre-sales opportunities and enhance your role within the AVAP Framework partner ecosystem, driving growth and innovation. Elevate your business today!
                                </p>
                                <a className="glowBtn-dark mb-0" onClick={() => {
                                    history.push("/connect");
                                    window.scrollTo(0, 0);
                                }} target='_blank'><span className="btnInner">Join Now</span></a>
                            </section>
                        </div>


                        <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                    </div>
                </div>
                <FlFooter></FlFooter>
            </div>
        </>
    );
};

export default App;
